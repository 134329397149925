export const appRoutes: any = {
    main: "/",
    notFound: "/404",
    trainReplacement: {
        schedule: "/train-replacement/schedule"
    },
    auth: {
        login: "/auth/login",
        registerCompany: "/auth/register-company",
        register: "/auth/register",
        forgotPassword: "/auth/forgot-password",
        changePassword: "/auth/change-password/:hash"
    },
    user: {
        profile: "/user/profile"
    },
    settings: {
        contactInfo: "/settings/contact-info"
    },
    quotes: {
        list: "/quotes/list"
    },
    booking: {
        list: "/booking/list",
        showDetails: "/booking/show-details/:id",
        details: "/booking/details/:id",
        cancel: "/booking/cancel/:id",
        changeBook: "/booking/change-book",
        vehicleLocation: "/booking/vehicle-location/:id",
    },
    purchases: {
        showDetails: "/purchases/show-details/:id",
        details: "/purchases/details/:id",
        cancel: "/purchases/cancel/:id"
    },
    payment: {
        callback: "/payment/callback"
    },
    journey: {
        rate: "/journey/rate/:id"
    },
    buses: {
        search: "/buses/search"
    },
    dashboard: {
        title: "Dashboard",
        main: { title: "Dashboard", path: "/dashboard" },
        company: {
            title: "Company Dashboard",
            main: { title: "Dashboard Main", path: "/dashboard/company/main" },
            details: { title: "Company Details", path: "/dashboard/company/details" },
            booking: {
                title: "Bookings",
                main: { title: "Bookings List", path: "/dashboard/company/booking/list" },
                details: { title: "Booking Details", path: "/dashboard/company/booking/details/:id" },
            },
            configurations: {
                title: "Configurations",
                main: "/dashboard/company/configurations",
                cancellationFees: {
                    title: "Cancellation Fees",
                    main: { title: "Cancellation Fee List", path: "/dashboard/company/configurations/cancellation-fees/list" },
                },
                accessibility: { title: "Accessibility", path: "/dashboard/company/configurations/accessibility" },
            },
            driverCosts: {
                title: "Driver Costs",
                main: { title: "Driver Cost List", path: "/dashboard/company/driver-costs/list" },
                basicDetails: { title: "Driver Cost Basic Details", path: "/dashboard/company/driver-costs/basic-details/:id" },
                exceptionDetails: { title: "Driver Cost Exception Details", path: "/dashboard/company/driver-costs/exception-details/:id" },
                addBasic: { title: "Add Basic Driver Cost", path: "/dashboard/company/driver-costs/add-basic" },
                addException: { title: "Add Exception Driver Cost", path: "/dashboard/company/driver-costs/add-exception" }
            },
            drivingCosts: {
                title: "Driving Costs",
                main: { title: "Driving Cost List", path: "/dashboard/company/driving-costs/list" },
                basicDetails: { title: "Driving Cost Basic Details", path: "/dashboard/company/driving-costs/basic-details/:id" },
                exceptionDetails: { title: "Driving Cost Exception Details", path: "/dashboard/company/driving-costs/exception-details/:id" },
                addBasic: { title: "Add Basic Driving Cost", path: "/dashboard/company/driving-costs/add-basic" },
                addException: { title: "Add Exception Driving Cost", path: "/dashboard/company/driving-costs/add-exception" }
            },
            vehicleCosts: {
                title: "Vehicle Costs",
                main: { title: "Vehicle Cost List", path: "/dashboard/company/vehicle-costs/list" },
                basicDetails: { title: "Vehicle Cost Basic Details", path: "/dashboard/company/vehicle-costs/basic-details/:id" },
                exceptionDetails: { title: "Vehicle Cost Exception Details", path: "/dashboard/company/vehicle-costs/exception-details/:id" },
                addBasic: { title: "Add Basic Vehicle Cost", path: "/dashboard/company/vehicle-costs/add-basic" },
                addException: { title: "Add Exception Vehicle Cost", path: "/dashboard/company/vehicle-costs/add-exception" }
            },
            postInvoicing: {
                title: "Post Invoicing",
                main: { title: "Post Invoicing List", path: "/dashboard/company/post-invoicing/list" },
                details: { title: "Post Invoicing Details", path: "/dashboard/company/post-invoicing/details/:id" }
            },
            users: {
                title: "Users",
                main: { title: "User List", path: "/dashboard/company/users/list" },
                edit: { title: "Edit User", path: "/dashboard/company/users/edit/:id" },
                add: { title: "Create User", path: "/dashboard/company/users/create" }
            },
            vehicles: {
                title: "Vehicles",
                main: { title: "Vehicle List", path: "/dashboard/company/vehicles/list" },
                add: { title: "Add Vehicle", path: "/dashboard/company/vehicles/add" },
                edit: { title: "Edit Vehicle", path: "/dashboard/company/vehicles/edit/:id" },
                occupancy: {
                    title: "Vehicle Occupancy", path: "/dashboard/company/vehicles/occupancy"
                }
            },
            quoteRequests: {
                title: "Quote Requests",
                main: { title: "Quote Request List", path: "/dashboard/company/quote-requests/list" },
                details: { title: "Quote Request Details", path: "/dashboard/company/quote-requests/details/:id" }
            },
            reporting: {
                title: "Reporting",
                main: { title: "Reporting", path: "/dashboard/company/reporting/list" }
            },
            drivingRequests: {
                title: "Driving Requests",
                main: { title: "Driving Request List", path: "/dashboard/company/driving-requests/list" },
                response: { title: "Driving Request Response", path: "/dashboard/company/driving-requests/response/:id" }

            }
        },
        admin: {
            title: "Admin Dashboard",
            main: { title: "Dashboard Main", path: "/dashboard/admin/main" },
            commissionRates: { title: "Commission Rates", path: "/dashboard/admin/commission-rates" },
            applicants: {
                title: "Applicants",
                main: { title: "Applicant List", path: "/dashboard/admin/applicants/list" },
                reply: { title: "Reply Applicant", path: "/dashboard/admin/applicants/reply/:id" },
                deny: { title: "Deny Applicant", path: "/dashboard/admin/applicants/deny/:id" },
            },
            booking: {
                title: "Bookings",
                main: { title: "Booking List", path: "/dashboard/admin/booking/list" },
                details: { title: "Booking Details", path: "/dashboard/admin/booking/details/:id" },
            },
            configurations: {
                title: "Configurations",
                main: "/dashboard/admin/configurations",
                cancellationFees: {
                    title: "Cancellation Fees",
                    main: { title: "Cancellation Fee List", path: "/dashboard/admin/configurations/cancellation-fees/list" },
                    create: { title: "New Cancellation Fee", path: "/dashboard/admin/configurations/cancellation-fees/create" },
                    edit: { title: "Edit Cancellation Fee", path: "/dashboard/admin/configurations/cancellation-fees/edit/:id" },
                    minimum: { title: "Minimum Cancellation Fee", path: "/dashboard/admin/configurations/cancellation-fees/minimum" }
                },
                generalSettings: { title: "General Settings", path: "/dashboard/admin/configurations/general-settings" },
                accessibility: { title: "Accessibility", path: "/dashboard/admin/configurations/accessibility" },
            },
            customers: {
                title: "Customers",
                main: { title: "Customer List", path: "/dashboard/admin/customers/list" },
                edit: { title: "Edit Customer", path: "/dashboard/admin/customers/edit/:id" }
            },
            dealCustomers: {
                title: "Deal Customers",
                main: { title: "Deal Customer List", path: "/dashboard/admin/deal-customers/list" },
                details: { title: "Deal Customer Details", path: "/dashboard/admin/deal-customers/details/:id" }
            },
            deviation: {
                title: "Deviations",
                main: { title: "Deviation List", path: "/dashboard/admin/deviation/list" },
                details: { title: "Deviation Details", path: "/dashboard/admin/deviation/details/:id" }
            },
            transportCompanies: {
                title: "Transport Companies",
                main: { title: "Transport Company List", path: "/dashboard/admin/transport-companies/list" },
                edit: { title: "Edit Transport Company", path: "/dashboard/admin/transport-companies/edit/:id" },
                add: { title: "Add Transport Company", path: "/dashboard/admin/transport-companies/add" },
            },
            users: {
                title: "Users",
                main: { title: "User List", path: "/dashboard/admin/users/list" },
                edit: { title: "Edit User", path: "/dashboard/admin/users/edit/:id" },
                add: { title: "Create User", path: "/dashboard/admin/users/create" }
            },
            externalIntegration: {
                title: "External Integration",
                main: { title: "External Integration List", path: "/dashboard/admin/external-integration/list" },
                edit: { title: "Edit External Integration", path: "/dashboard/admin/external-integration/edit/:id" },
                create: { title: "Create External Integration", path: "/dashboard/admin/external-integration/create" }
            },
            quoteRequests: {
                title: "Quote Requests",
                main: { title: "Quote Request List", path: "/dashboard/admin/quote-requests/list" },
                details: { title: "Quote Request Details", path: "/dashboard/admin/quote-requests/details/:id" }
            },
            temporaryPriceLists: {
                title: "Temporary Price Lists",
                add: { title: "Add Temporary Price List", path: "/dashboard/admin/prices/add" }
            },
            reporting: {
                title: "Reporting",
                main: { title: "Reporting", path: "/dashboard/admin/reporting/list" }
            },
            invoices: {
                title: "Invoices",
                main: { title: "Invoice List", path: "/dashboard/admin/invoices/list" },
                createList: { title: "Create Invoices", path: "/dashboard/admin/invoices/create-list" }
            },
            customerDeals: {
                title: "Customer Deals",
                main: { title: "Customer Deal List", path: "/dashboard/admin/customer-deals/list" },
                create: { title: "Create Customer Deal", path: "/dashboard/admin/customer-deals/create" },
                edit: { title: "Edit Customer Deal", path: "/dashboard/admin/customer-deals/edit/:id" },
                priceLists: {
                    title: "Customer Deal Price Lists",
                    main: { title: "Customer Deal Price Lists", path: "/dashboard/admin/customer-deals/edit/:id" },
                    create: { title: "Create Price List", path: "/dashboard/admin/customer-deals/price-list/create" },
                    edit: { title: "Edit Price List", path: "/dashboard/admin/customer-deals/price-list/edit/:id" },
                },
            },
            operatorDeals: {
                title: "Operator Deals",
                main: { title: "Operator Deal List", path: "/dashboard/admin/operator-deals/list" },
                create: { title: "Create Operator Deal", path: "/dashboard/admin/operator-deals/create" },
                edit: { title: "Edit Operator Deal", path: "/dashboard/admin/operator-deals/edit/:id" },
                priceLists: {
                    title: "Operator Deal Price Lists",
                    main: { title: "Operator Deal Price Lists", path: "/dashboard/admin/operator-deals/edit/:id" },
                    create: { title: "Create Price List", path: "/dashboard/admin/operator-deals/price-list/create" },
                    edit: { title: "Edit Price List", path: "/dashboard/admin/operator-deals/price-list/edit/:id" },
                },
            },
            searchOperators: {
                title: "Search Operators",
                main: { title: "Search Operators", path: "/dashboard/admin/search-operators" }
            },
            purchases: {
                title: "Purchases",
                details: { title: "Purchase List", path: "/dashboard/admin/purchases/details/:id" }
            }
        },
        dealOrg: {
            title: "Deal Organization Dashboard",
            main: { title: "Dashboard Main", path: "/dashboard/deal-org/main" },
            manage: {
                title: "Manage Organization",
                main: { title: "Manage Organization", path: "/dashboard/deal-org/manage/:orgId?" },
                editUnit: { title: "Edit Unit", path: "/dashboard/deal-org/manage/edit-unit/:orgId?" },
                addUnit: { title: "Add Unit", path: "/dashboard/deal-org/manage/add-unit/:orgId?" },
            },
            details: { title: "Deal Organization Details", path: "/dashboard/deal-org/details" },
            users: {
                title: "Users",
                main: { title: "User List", path: "/dashboard/deal-org/users/list" },
                edit: { title: "Edit User", path: "/dashboard/deal-org/users/edit/:id" },
                add: { title: "Create User", path: "/dashboard/deal-org/users/create" }
            },
            quoteRequests: {
                title: "Quote Requests",
                details: { title: "Quote Request Details", path: "/dashboard/deal-org/quote-requests/details/:id" }
            },
            reporting: {
                title: "Reporting",
                main: { title: "Reporting", path: "/dashboard/deal-org/reporting/list" }
            },
            addresses: {
                title: "Addresses",
                main: { title: "Address List", path: "/dashboard/deal-org/address/list/:customerOrganizationId?" },
                add: { title: "Add Address", path: "/dashboard/deal-org/address/add" },
                edit: { title: "Add Address", path: "/dashboard/deal-org/address/edit/:id" },
            },
            purchases: {
                title: "Purchases",
                main: {
                    title: "Purchase List", path: "/dashboard/deal-org/purchases/list",
                    historyReplacements: [{ from: "settings.contactInfo", to: "dashboard.dealOrg.main" },
                    { from: "payment.callback", to: "dashboard.dealOrg.main" }]
                },
                details: { title: "Purchase List", path: "/dashboard/deal-org/purchases/details/:id" }
            },
            quoteAndQuoteRequests: {
                title: "Quote and Quote Requests",
                main: { title: "Quote and Quote Request List", path: "/dashboard/deal-org/quote-and-quote-requests/list" },
            },
            errorCauses: {
                title: "Error Causes",
                main: { title: "Error Cause List", path: "/dashboard/deal-org/error-cause/list/:customerOrganizationId?" }
            },
            events: {
                title: "Events",
                main: { title: "Event List", path: "/dashboard/deal-org/event/list/:customerOrganizationId?" },
                add: { title: "Add Event", path: "/dashboard/deal-org/event/add" },
                edit: { title: "Edit Event", path: "/dashboard/deal-org/event/edit/:id" }
            },
            invoices: {
                title: "Invoices",
                main: { title: "Invoice List", path: "/dashboard/deal-org/invoices/list" }
            }
        },
        trainOrg: {
            title: "Train Organization Dashboard",
            main: { title: "Dashboard Main", path: "/dashboard/train-org/main" },
            lines: {
                title: "Lines",
                main: { title: "Line List", path: "/dashboard/train-org/lines/list/:customerOrganizationId?" },
                create: { title: "Create Line", path: "/dashboard/train-org/lines/create" },
                details: { title: "Line Details", path: "/dashboard/train-org/lines/details/:id" }
            },
            users: {
                title: "Users",
                main: { title: "User List", path: "/dashboard/train-org/users/list" },
                edit: { title: "Edit User", path: "/dashboard/train-org/users/edit/:id" },
                add: { title: "Create User", path: "/dashboard/train-org/users/create" }
            },
            quoteAndQuoteRequests: {
                title: "Quote and Quote Requests",
                main: { title: "Quote and Quote Request List", path: "/dashboard/train-org/quote-and-quote-requests/list" },
            },
            quoteRequests: {
                title: "Quote Requests",
                details: { title: "Quote Request Details", path: "/dashboard/train-org/quote-requests/details/:id" }
            },
            drivingRequests: {
                title: "Driving Requests",
                main: { title: "Driving Request List", path: "/dashboard/train-org/driving-requests/list" },
                searchOperators: { title: "Search Operators", path: "/dashboard/train-org/driving-requests/search-operators" },
                create: { title: "Driving Request Details", path: "/dashboard/train-org/driving-requests/create" },
                details: { title: "Driving Request Details", path: "/dashboard/train-org/driving-requests/details/:id" }
            },
            variants: {
                title: "Variants",
                main: {
                    title: "Variant List", path: "/dashboard/train-org/variants/list",
                    historyReplacements: [{ from: "dashboard.trainOrg.variants.create", to: "dashboard.trainOrg.lines.main" }]
                },
                create: { title: "Variant List", path: "/dashboard/train-org/variants/create" },
                details: { title: "Variant Details", path: "/dashboard/train-org/variants/details/:id" }
            },
            driveTimes: {
                title: "Driver Time",
                main: { title: "Driver Time List", path: "/dashboard/train-org/drive-times/list" },
            },
            timeTables: {
                title: "Time Table",
                main: { title: "Time Table List", path: "/dashboard/train-org/time-tables/list" },
                create: { title: "Time Table Details", path: "/dashboard/train-org/time-tables/create" },
                details: {
                    title: "Time Table Details", path: "/dashboard/train-org/time-tables/details/:id",
                    historyReplacements: [{ from: "dashboard.trainOrg.timeTables.main", to: "dashboard.trainOrg.timeTables.main" }]
                },
                addBuses: {
                    title: "Add Buses", path: "/dashboard/train-org/time-tables/add-buses/:id",
                    historyReplacements: [{ from: "dashboard.trainOrg.timeTables.main", to: "dashboard.trainOrg.timeTables.main" }]
                },
                trafficOverview: { title: "Traffic Overview", path: "/dashboard/train-org/time-tables/traffic-overview" }
            },
            departures: {
                title: "Departures",
                main: {
                    title: "Departures", path: "/dashboard/train-org/departures",
                    historyReplacements: [{ from: "dashboard.trainOrg.timeTables.main", to: "dashboard.trainOrg.timeTables.main" }]
                }
            },
            reporting: {
                title: "Reporting",
                main: { title: "Reporting", path: "/dashboard/train-org/reporting/list" }
            }
        },
        trafficControl: {
            title: "Traffic Control Dashboard",
            main: { title: "Dashboard Main", path: "/dashboard/traffic-control/main" },
            quoteAndQuoteRequests: {
                title: "Quote and Quote Requests",
                main: { title: "Quote and Quote Request List", path: "/dashboard/deal-org/quote-and-quote-requests/list" },
            },
            quoteRequests: {
                title: "Quote Requests",
                details: { title: "Quote Request Details", path: "/dashboard/deal-org/quote-requests/details/:id" }
            },
            drivingRequests: {
                title: "Driving Requests",
                main: { title: "Driving Request List", path: "/dashboard/train-org/driving-requests/list" },
                searchOperators: { title: "Search Operators", path: "/dashboard/train-org/driving-requests/search-operators" },
                create: { title: "Create Driving Request", path: "/dashboard/train-org/driving-requests/create" },
                details: { title: "Driving Request Details", path: "/dashboard/train-org/driving-requests/details/:id" }

            },
        }
    },

    components: {
        searchCard: "/components/search-card",
        header: "/components/header",
        mainBenefits: "/components/main-benefits",
        mainWorsks: "/components/main-works"
    },
    cms: {
        page: "/page/:slug"
    }
}
export const findRouteHistoryReplacement = (currentPath: string, backStack: Location[]): string | undefined => {
    if (!backStack)
        return undefined;
    currentPath = currentPath.replace("/en", "").replace("/sv", "");
    let routeObject = findRouteObjectByPath(appRoutes, currentPath);
    if (!routeObject)
        return undefined;
    let replacementPaths: any[] = [];
    routeObject.historyReplacements?.forEach((replacement: any) => {
        let routeParts = replacement.from.split('.')
        let route = appRoutes;
        routeParts.forEach((fr: string) => {
            route = route[fr];
        })
        const fromPath = route;
        routeParts = replacement.to.split('.')
        route = { ...appRoutes };
        routeParts.forEach((fr: string) => {
            route = route[fr];
        })
        replacementPaths.push({ fromPath: fromPath.path ? fromPath.path : fromPath, toPath: route.path });
    })
    const replacementPath = replacementPaths.find(rp => backStack[backStack.length - 2].pathname.replace("/en", "").replace("/sv", "").includes(rp.fromPath.split(':')[0]));
    if (!replacementPath)
        return undefined;
    let nearestBackStack = backStack.find(b => b.pathname.includes(replacementPath.toPath.split(':')[0]));
    return nearestBackStack ? nearestBackStack?.pathname.replace("/en", "").replace("/sv", "") + nearestBackStack?.search : undefined;

}
const findRouteObjectByPath = (object: any, path: string): any => {
    for (const key in object) {
        if (key === 'path' && isSamePath(object[key], path)) {
            return object;
        }
        else if (typeof object[key] == 'object') {
            const result = findRouteObjectByPath(object[key], path);
            if (result) {
                return result;
            }
        }
    }
    return null;
}
const isSamePath = (path1: string, path2: string) => {
    if (path1 == path2)
        return true;
    const path1Parts = path1.toString().split('/');
    const path2Parts = path2.toString().split('/');

    if (path1Parts.length > 0 && path2Parts.length > 0 && (path1Parts[path1Parts.length - 1].includes(":") || path2Parts[path2Parts.length - 1].includes(":")) &&
        path1Parts.map((s, index) => { return index < path1Parts.length - 1 ? s : "" }).join("") ==
        path2Parts.map((s, index) => { return index < path2Parts.length - 1 ? s : "" }).join("")) {
        return true;
    }
    return false;

}
