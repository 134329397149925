import moment from "moment";
import i18n from "./i18n";

export { }
declare global {
    export interface Number {
        toCurrencyFormat(hideDecimals?: boolean, devideToHundred?: boolean): string;
    }
    export interface String {
        toCapitilizedFormat(): string;
        toPascalCase(): string;
        interpolateError(): any;
        toFixedApiDateString(): string;
        toDateTimeFormat(): string;
    }
    interface Window {
        config: any;
    }
    interface Window {
        dataLayer: any;
    }


}
var currencyFormatter = new Intl.NumberFormat('sw-SW', {
    style: 'currency',
    currency: 'SEK'
});
Number.prototype.toCurrencyFormat = function (this: number, hideDecimals?: boolean, devideToHundred?: boolean): string {
    let re = /\,/gi;
    if (isNaN(this))
        return "-"
    if (hideDecimals)
        currencyFormatter = new Intl.NumberFormat('sw-SW', {
            style: 'currency',
            currency: 'SEK',
            maximumFractionDigits: 0
        });
    else
        currencyFormatter = new Intl.NumberFormat('sw-SW', {
            style: 'currency',
            currency: 'SEK'
        });
    return currencyFormatter.format(devideToHundred ? this / 100 : this).replace("SEK", "").replace(re, " ").replace(".", ",");
};
String.prototype.toCapitilizedFormat = function (this: string): string {
    return this.charAt(0).toUpperCase() + this.slice(1)
}
String.prototype.toPascalCase = function () {
    return this
        .toLowerCase()
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/\s+(.)(\w*)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3}`
        )
        .replace(new RegExp(/\w/), s => s.toUpperCase());
};
String.prototype.interpolateError = function (this: string) {
    let errorTexts = this.split(/\s*,\s*/);
    if (errorTexts.length === 3) {
        return i18n.t(errorTexts[0], { dynamicValue: errorTexts[1], dynamicValue2: errorTexts[2] });
    } else if (errorTexts.length === 2) {
        return i18n.t(errorTexts[0], { dynamicValue: errorTexts[1] });
    } else if (errorTexts[0] && !errorTexts[0].includes(" ")) {
        return i18n.t(errorTexts[0]);
    }
    else {
        return errorTexts[0];
    }
}

String.prototype.toFixedApiDateString = function (this: string) {
    if (!this)
        return "";
    return moment(this).format("YYYY-MM-DDT00:00+00:00");
}
String.prototype.toDateTimeFormat = function (this: string) {
    if (!this)
        return "";
    return moment(this).format("YYYY-MM-DD HH:mm");
}